import React, { useContext } from "react"
import { linkResolver } from "../prismic/linkResolver"
import Wrapper from "../components/Wrapper"
import Grid from "../components/Grid"
import GuideComponent from "../components/Guide"
import StoreContext from "../context/StoreContext"
import { defaultLang } from "../../prismic-configuration"
import { graphql } from "gatsby"
import getTranslations from "../translations"

export const query = graphql`
  query GuideQuery($lang: String) {
    prismic {
      allGuides(lang: $lang) {
        edges {
          node {
            _meta {
              lang
            }
          }
        }
      }
    }
  }
`

const Guide = (props) => {
  const context = useContext(StoreContext)
  const { setModalVisibility, setModalContent } = context
  const lang = props.pageContext.lang
  const t = getTranslations(lang);

  const titleLabel = t.guide.title

  const title = "Styleråd"
  const heading = "Vi hjælper dig på vej"

  const handleClick = (title, text) => {
    setModalVisibility(true)
    setModalContent(title, text, "image")
  }

  return (
    <>
      <div
        className="measuring-guide-container"
        style={{ paddingBottom: "80px" }}
      >
        <h1 class="fs--l mb--xs">{t.guide.title}</h1>
        <div>
          <GuideComponent lang={lang} />
        </div>

        <div style={{ clear: "both" }}></div>
        <a
          onClick={() => {
            handleClick({titleLabel}, <GuideComponent lang={lang} />)
          }}
        ></a>
      </div>
    </>
  )
}

Guide.query = query

export default Guide
